<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
    <!-- <fuse-vertical-navigation
        class="dark bg-red-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false"
    > -->
        <!-- Navigation header hook -->
        <!-- <ng-container fuseVerticalNavigationContentHeader> -->
            <!-- Logo -->
            <!-- <div class="flex h-20 items-center px-8 pt-6">
                <img (click)="gotoHome()"
                    class="w-48"
                    src="images/logo/Group.png"
                    alt="Logo image"
                />
            </div>
        </ng-container>
    </fuse-vertical-navigation> -->
}

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none sm:h-20 md:px-6 print:hidden"
    >
        @if (!isScreenSmall) {
            <!-- Logo -->
            <div class="mx-2 flex items-center lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="w-48 dark:hidden" style="cursor: pointer" src="images/logo/Group.png" (click)="gotoHome()"/>
                    <img
                        class="hidden w-48 dark:flex"
                        src="images/logo/Group.png"  style="cursor: pointer" (click)="gotoHome()"
                    />
                </div>
                <img class="flex w-48 lg:hidden"   style="cursor: pointer" src="images/logo/Group.png" (click)="gotoHome()"/>
            </div>
            <!-- Horizontal navigation -->
            <!--            <fuse-horizontal-navigation-->
            <!--                class="mr-2"-->
            <!--                [name]="'mainNavigation'"-->
            <!--                [navigation]="navigation.horizontal"-->
            <!--            ></fuse-horizontal-navigation>-->
        }
        <!-- Navigation toggle button -->
        @if (isScreenSmall) {
            <div class="mx-2 flex items-center lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="w-48 dark:hidden" style="cursor: pointer" src="images/logo/Group.png"  (click)="gotoHome()"/>
                    <img
                        class="hidden w-48 dark:flex"
                        src="images/logo/Group.png"   style="cursor: pointer" (click)="gotoHome()"
                    />
                </div>
                <img class="flex w-48 lg:hidden"  style="cursor: pointer" src="images/logo/Group.png"   (click)="gotoHome()"/>
            </div>


            <!-- <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')"
            >
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button> -->
        }
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <!--            <languages></languages>-->
            <button *ngIf="!getUserIsLoggedIn()" (click)="onLogOutClick()" mat-stroked-button>Sign In</button>
            <button *ngIf="!getUserIsLoggedIn()" (click)="signUp()" mat-stroked-button>Sign up</button>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>

            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="getUserIsLoggedIn()">
                <span class="relative">
                    <img
                        class="h-7 w-7 rounded-full"
                        src="images/profile.png"
                        alt=""
                    />
                </span>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Profile</span>
                </button>
                <button mat-menu-item (click)="onOrderListClick()">
                    <mat-icon>list_alt</mat-icon>
                    <span>Order List</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="onLogOutClick()">
                    <mat-icon>logout</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <!-- <button  (click)="onProfile()">

            </button> -->
            <!--            <search [appeasrance]="'bar'"></search>-->
            <!--            <shortcuts></shortcuts>-->
            <!--            <messages></messages>-->
            <!--            <notifications></notifications>-->
            <!--            <button-->
            <!--                class="lg:hidden"-->
            <!--                mat-icon-button-->
            <!--                (click)="quickChat.toggle()"-->
            <!--            >-->
            <!--                <mat-icon-->
            <!--                    [svgIcon]="'heroicons_outline:chat-bubble-left-right'"-->
            <!--                ></mat-icon>-->
            <!--            </button>-->
            <!--            <user></user>-->
        </div>
    </div>

    <!-- Content -->
    <div class="flex w-full flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <div class="layout-container">
                <router-outlet></router-outlet>
            </div>
        }
    </div>

    <!-- Footer -->
    <!--    <div-->
    <!--        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center border-t px-4 dark:bg-transparent sm:h-20 md:px-6 print:hidden"-->
    <!--    >-->
    <!--        <span class="text-secondary font-medium"-->
    <!--            >Fuse &copy; {{ currentYear }}</span-->
    <!--        >-->
    <!--    </div>-->
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
